<template>
  <div class="pc-home">
    <pc-header :hideMenu="hideMenu"></pc-header>
    <div class="shop-info">
      <div class="top-banner">
        <Swipe
          ref="swipe"
          class="banner"
          :autoplay="3000"
          :loop="true"
          :show-indicators="false"
          :height="300"
        >
          <SwipeItem v-for="(item, i) in bannerList" :key="i">
            <img :src="item" alt="" @dragstart.prevent />
          </SwipeItem>
        </Swipe>
        <div class="top-info flex-center-between">
          <div class="flex-center">
            <div class="img">
              <img :src="shopInfo.Logo" alt="" />
            </div>
            <div>
              <div class="name">{{ shopInfo.Name }}</div>
              <div class="desc">{{ shopInfo.Intro }}</div>
            </div>
          </div>
          <div>
            <el-button class="btn" @click="toFollow">
              <i :class="isFollow ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
              {{ $t('guan-zhu') }}
            </el-button>
          </div>
        </div>
      </div>
      <div class="top-title">
        <div class="title-inner flex-center-start">
          <div
            class="title"
            :class="activeIndex == 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            {{ $t('tui-jian') }}
          </div>
          <div
            class="title"
            :class="activeIndex == 2 ? 'active' : ''"
            @click="changeTab(2)"
          >
            {{ $t('suo-you-chan-pin') }}
          </div>
        </div>
      </div>

      <div class="center-main">
        <div class="recommend" v-if="activeIndex == 1">
          <goodsList :list="goodsList"></goodsList>

          <div class="flex-center user-pager">
            <el-pagination
              background
              @current-change="currentChange"
              :current-page="page.current"
              :page-size="page.size"
              layout=" prev, pager,  next"
              :total="page.total"
            >
            </el-pagination>
          </div>
        </div>
        <div class="all-goods common-product" v-else>
          <div class="category-list">
            <div class="title">{{ $t('fen-lei') }}</div>
            <div class="list-box">
              <div
                class="list"
                @click="showAll"
                :class="form.category == '' ? 'active' : ''"
              >
                <div class="label">{{ $t('quan-bu-shang-pin') }}</div>
              </div>
              <div class="list" v-for="(item, i) in categoryList" :key="i">
                <div
                  class="label"
                  @click="changeShow(item, i)"
                  :class="form.category == item.ID ? 'active' : ''"
                >
                  {{ item.title }}
                  <i
                    class="el-icon-arrow-right"
                    v-if="
                      item.children && item.children.length > 0 && !item.show
                    "
                  ></i>
                  <i
                    class="el-icon-arrow-down"
                    v-if="
                      item.children && item.children.length > 0 && item.show
                    "
                  ></i>
                </div>
                <div class="child-box" :class="item.show ? 'show' : ''">
                  <div
                    class="sub-list"
                    v-for="(subItem, index) in item.children"
                    :key="index"
                  >
                    <div
                      class="sub-label"
                      @click="changeShowSub(subItem, i, index)"
                      :class="form.category == subItem.ID ? 'active' : ''"
                    >
                      {{ subItem.title }}
                      <i
                        class="el-icon-arrow-right"
                        v-if="
                          subItem.children &&
                          subItem.children.length > 0 &&
                          !subItem.show
                        "
                      ></i>
                      <i
                        class="el-icon-arrow-down"
                        v-if="
                          subItem.children &&
                          subItem.children.length > 0 &&
                          subItem.show
                        "
                      ></i>
                    </div>

                    <div
                      class="category-box"
                      :class="subItem.show ? 'show' : ''"
                    >
                      <div
                        class="category"
                        v-for="(category, m) in subItem.children"
                        :key="m"
                        :class="form.category == category.ID ? 'active' : ''"
                        @click="chooseCategory(category)"
                      >
                        {{ category.title }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="goods-main">
            <div class="top-filter flex-center-start">
              <div
                class="option"
                :class="tabIndex == 1 ? 'active' : ''"
                @click="changeOption(1)"
              >
                {{ $t('zong-he') }}
              </div>
              <div
                class="option flex-center"
                :class="tabIndex == 2 ? 'active' : ''"
                @click="changeOption(2)"
              >
                {{ $t('xiao-shou-liang') }}
                <div class="flex-column-center icon">
                  <i
                    class="el-icon-caret-top"
                    :class="tabIndex == 2 && sortType == 'asc' ? 'active' : ''"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="tabIndex == 2 && sortType == 'desc' ? 'active' : ''"
                  ></i>
                </div>
              </div>
              <div
                class="option flex-center"
                :class="tabIndex == 3 ? 'active' : ''"
                @click="changeOption(3)"
              >
                {{ $t('jia-ge') }}
                <div class="flex-column-center icon">
                  <i
                    class="el-icon-caret-top"
                    :class="tabIndex == 3 && sortType == 'asc' ? 'active' : ''"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="tabIndex == 3 && sortType == 'desc' ? 'active' : ''"
                  ></i>
                </div>
              </div>
              <div
                class="option flex-center"
                :class="tabIndex == 4 ? 'active' : ''"
                @click="changeOption(4)"
              >
                {{ $t('shang-xin') }}
                <div class="flex-column-center icon">
                  <i
                    class="el-icon-caret-top"
                    :class="tabIndex == 4 && sortType == 'asc' ? 'active' : ''"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="tabIndex == 4 && sortType == 'desc' ? 'active' : ''"
                  ></i>
                </div>
              </div>
            </div>
            <goodsList class="line-5" :list="goodsList"></goodsList>

            <div class="flex-center user-pager">
              <el-pagination
                background
                @current-change="currentChange"
                :current-page="page.current"
                :page-size="page.size"
                layout=" prev, pager,  next"
                :total="page.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-bar></footer-bar>
  </div>
</template>
<script>
import {
  followShop,
  getShopFollowList,
  getShopList,
  shopProductList,
  addShopRead
} from '@/api/shop'
import '@vant/touch-emulator'
import pcHeader from '@/components/pcHeader'
import footerBar from '@/components/footer'
import goodsList from '@/components/goodsList'
import { SwipeItem, Swipe } from 'vant'
export default {
  components: {
    pcHeader,
    footerBar,
    goodsList,
    SwipeItem,
    Swipe
  },
  data() {
    return {
      tabIndex: 1,
      sortType: 'desc',
      shopId: '',
      shopInfo: {},
      form: {
        category: '',
        sort: ''
      },
      hideMenu: false,
      isFollow: false,
      activeIndex: 1,
      page: {
        size: 10,
        current: 1,
        total: 1
      },
      bannerList: [require('@/assets/imgs/credit-banner1.png')],
      goodsList: []
    }
  },
  computed: {
    categoryList() {
      return this.$store.state.categoryList
    },
    lang() {
      return this.$store.state.lang
    },
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  mounted() {
    this.shopId = this.$route.query.id
    document.body.addEventListener('scroll', this.pageScroll)
    this.initGoods()
    this.init()
    this.initShop()
    this.initCategory()
    this.initRead()
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.pageScroll)
  },
  methods: {
    initRead() {
      if (!this.isLogin) {
        return
      }
      addShopRead()
    },
    initCategory() {
      this.$store.dispatch('initCategory', this.lang)
    },
    initGoods() {
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        ShopID: parseFloat(this.shopId),
        CategoryID: this.form.category || null,
        Sort: this.form.sort
      }).then((res) => {
        this.goodsList = res.data.Items
      })
    },
    initShop() {
      getShopList({
        ID: parseInt(this.shopId,10)
      }).then((res) => {
        this.shopInfo = res.data.Items[0]
        if (this.shopInfo.Banner) {
          this.bannerList = this.shopInfo.Banner.split(',')
        }
      })
    },
    pageScroll(e) {
      let top = e.target.scrollTop
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (top > 5) {
          this.hideMenu = true
        } else {
          this.hideMenu = false
        }
      }, 100)
      if (top > 100) {
        this.hideMenu = true
      }
    },
    init() {
      if (!this.isLogin) {
        return
      }
      getShopFollowList({
        current: 1,
        pageSize: 100
      }).then((res) => {
        let list = res.data.Items
        let index = list.findIndex((v) => {
          return (v.ShopID = this.shopId)
        })
        this.isFollow = index > -1
      })
    },
    toFollow() {
      if (this.isFollow) {
        return
      }
      followShop({
        ShopID: parseFloat(this.shopId)
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('guan-zhu-cheng-gong'))
          this.isFollow = true
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    changeTab(i) {
      this.activeIndex = i
    },
    currentChange(page) {},
    changeShow(data, i) {
      let show = !data.show
      this.$set(this.categoryList[i], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeShowSub(data, i, index) {
      if (!data.children || data.children.length == 0) {
        return
      }
      let show = !data.show
      this.$set(this.categoryList[i].children[index], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeOption(i) {
      if (i == this.tabIndex) {
        this.sortType = this.sortType == 'asc' ? 'desc' : 'asc'
      } else {
        this.sortType = 'desc'
        this.tabIndex = i
      }
      this.form.sort =
        i == 1
          ? ''
          : i == 2
          ? 'sales'
          : i === 3
          ? this.sortType == 'asc'
            ? 'MaxPrice'
            : 'MinPrice'
          : ''
      this.page.current = 1
      this.initGoods()
    },
    chooseCategory(data) {
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    showAll() {
      this.form.category = ''
      this.page.current = 1
      this.initGoods()
    }
  }
}
</script>